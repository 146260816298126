import React from "react"
import loadable from "@loadable/component"
import useDelayedRender from "@hooks/useDelayedRender"
import backgroundDesktopImage from "@images/ai/background-desktop.jpg"
import { GLOBAL_APPLICATION_INTERNAL_LINKS } from "@links"
import {
  AI_BACKGROUND_IMAGE_TITLE,
  AI_BACKGROUND_IMAGE_DESCRIPTION,
  CATCH_UP_SECTION_TITLE,
  CATCH_UP_SECTION_DESCRIPTION,
  VIDEO_SECTION_TEXT_ADDITIONAL_STYLES,
  CONTACT_US_BUTTON_TEXT,
  WHY_CHOOSE_ITEMS,
  FLIPPABLE_BENEFITS_SECTION_TITLE,
  CUSTOM_BADGES
} from "./constants"
import { 
  StyledCenteredContainer, 
  StyledContactUsButton 
} from "./styled"

const VideoSection = loadable(() => import("@common/CommonSections/VideoSection/VideoSection"));

const Competencies = loadable(() => import("./components/Competencies"));

const Industries = loadable(() => import("./components/Industries"));

const Benefits = loadable(() => import("./components/Benefits"));

const Services = loadable(() => import("./components/Services"));

const TechnologyStack = loadable(() => import("./components/TechnologyStack"));

const Feedback = loadable(() => import("./components/Feedback"));

const ProudSection = loadable(() => import("@components/Landing/components/ProudSectionWrapper/ProudSectionWrapper"));

const BenefitsFlippableListSection = loadable(() => import("@common/CommonSections/BenefitsFlippableListSection"));

const CrawlableSecondCatchUpSection = loadable(() => import("@common/CommonSections/CrawlableSecondCatchUpSection"));

const ArtificialIntelligence = () => {
  const { isDelayed } = useDelayedRender(100);

  return (
    <>
    <VideoSection
      backgroundImage={backgroundDesktopImage}
      title={AI_BACKGROUND_IMAGE_TITLE}
      description={AI_BACKGROUND_IMAGE_DESCRIPTION}
      textContainerAdditionalStyles={VIDEO_SECTION_TEXT_ADDITIONAL_STYLES}
     />
      {isDelayed && (
        <>
          <StyledCenteredContainer>
            <Competencies />
            <Industries />
            <Benefits />
            <TechnologyStack />
          </StyledCenteredContainer>
          <Services />
          <StyledCenteredContainer>
            <Feedback />
            <ProudSection customBadges={CUSTOM_BADGES} />
            <BenefitsFlippableListSection heading={FLIPPABLE_BENEFITS_SECTION_TITLE} configuration={WHY_CHOOSE_ITEMS} />
          </StyledCenteredContainer>
          <CrawlableSecondCatchUpSection
            title={CATCH_UP_SECTION_TITLE}
            description={CATCH_UP_SECTION_DESCRIPTION}
            hasBigImage
          >
            <StyledContactUsButton
              to={GLOBAL_APPLICATION_INTERNAL_LINKS.CONTACT_US}
            >
              {CONTACT_US_BUTTON_TEXT}
            </StyledContactUsButton>
          </CrawlableSecondCatchUpSection>
        </>
      )}
    </>
  )
}

export default ArtificialIntelligence
